import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { FirebaseData, ObservableFirebaseList, ObservableFirebaseDetail } from '../services/core.service';

@Injectable({
  providedIn: 'root'
})
export class HelpdeskService {

  constructor(
    private db: AngularFireDatabase,
  ) { }

  detail(): ObservableFirebaseDetail {
    return this.db.object('setting').valueChanges();
  }

  send(values: FirebaseData): Promise<any> {
    console.log("connect.component try to send")
    return this.db.list('helpdesk').push(values);
  }
}
