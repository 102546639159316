import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private authService: AuthService,
    private db: AngularFireDatabase
  ) { }

  // создать подписку
  create(category: string) {
    this.authService.state.subscribe(authData => {
      this.db.object(`subscription/${this.authService.getLogin()}/${category}`).set({
        category: category
      });
    });
  }

  // удалить подписку
  delete(category: string) {
    this.authService.state.subscribe(authData => {
      this.db.list(`subscription/${this.authService.getLogin()}`).remove(category);
    });
  }

  // список категорий в подписке
  listCategory(): Observable<string[]> {
    return this.db.list(`subscription/${this.authService.getLogin()}`)
      .snapshotChanges().pipe(map(actions => {
        return actions.map(a => {
          return a.payload.key;
        });
      }));
  }

  // список данных подписки
  list() {
    return this.db.list(`subscription/${this.authService.getLogin()}`)
      .snapshotChanges().pipe(map(actions => {
        return actions.map(a => {
          const data = a.payload.val();
          data['key'] = a.payload.key;
          return data;
        });
      }));
  }
}
