import { Injectable } from '@angular/core';

import { map, switchMap } from 'rxjs/operators';
import { Observable, of, combineLatest } from 'rxjs';

import { AngularFireDatabase } from '@angular/fire/database';

import { SubscriptionService } from '../services/subscription.service';
import { AuthService } from '../services/auth.service';
import { CategoryModel } from '../model/category-model';
import { CoreService, FirebaseData } from '../services/core.service';

@Injectable({
  providedIn: 'root'
})
export class Ad2Service {

  ads = 'ads';

  constructor(
    private db: AngularFireDatabase,
    private subscriptionService: SubscriptionService,
    private authService: AuthService,
  ) { }

  // Список объявлений категории
  getAdList(category: string): Observable<{ [key: string]: any }> {
    return CoreService.snapshotChangesListToModel(
      this.db.list(this.ads, ref =>
        ref.orderByChild('category').equalTo(category)
      )
    );
  }

  // Список объявлений включенных в подписке
  list(): Observable<any[]> {
    return this.subscriptionService.listCategory()
      .pipe(
        switchMap(categories => {
          const arrayObserver = categories.map(setting =>
            this.getAdList(setting)
          );

          return arrayObserver.length === 0 ? of([]) : combineLatest(arrayObserver)
            .pipe(
              switchMap((items: Array<Array<any>>) =>
                of(
                  items.reduce((acc, subitems) =>
                    [...acc, ...subitems], [])
                )
              )
            );
        })
      );
  }

  // Список объявлений пользователя
  listMyAd(): Observable<FirebaseData[]> {
    return CoreService.snapshotChangesListToModel(
      this.db.list(this.ads, ref =>
        ref.orderByChild('user').equalTo(this.authService.getLogin())
      )
    );
  }

  // Удалить объявление
  removeAd(note: FirebaseData) {
    return this.db.list(this.ads).remove(note.key);
  }

  // Информация по объявлению
  detail(id: string): Observable<FirebaseData> {
    return CoreService.snapshotChangesObjectToModel(
      this.db.object(`${this.ads}/${id}`)
    );
  }

  // Список категорий
  listCategories(): Observable<string[]> {
    return this.db.list('categories').valueChanges()
      .pipe(
        map(items =>
          items.map(item =>
            item['name']
          )
        )
      );
  }

  // Список категорий
  listCategoriesFull(): Observable<CategoryModel[]> {
    return this.db.list('categories').valueChanges()
      .pipe(
        map(items => items as CategoryModel[])
      );
  }

  // Добавить объявление
  addAdForFields(note: any): Promise<any> {
    note['user'] = this.authService.getLogin();
    note['dateCreate'] = new Date().toISOString();

    return this.db.list(this.ads).push(note);
  }
}
