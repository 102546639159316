import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from '../services/auth.service';
import { FirebaseData, CoreService, ObservableFirebaseList, ObservableFirebaseDetail } from '../services/core.service';
import {CorequeryService} from "../services/corequery.service";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private db: AngularFireDatabase,
    private authService: AuthService,
    private coreService: CoreService,
    private corequeryService: CorequeryService,
  ) { }



  listOrdersShop() {
    return this.corequeryService.listBlock(`ordersShop/${this.authService.getLogin()}/orders`)
  }

  orderProductsShopDetail(id) {
    return this.corequeryService.listBlock(`ordersShop/${this.authService.getLogin()}/orders/${id}/products`)
  }

  orderShopDetail(id) {
    return this.corequeryService.detailBlock(`ordersShop/${this.authService.getLogin()}/orders`, id)
  }


  listForShop() {
    return CoreService.snapshotChangesListToModel(
      this.db.list('zakaz', ref =>
        ref.orderByChild('magazine').equalTo(this.authService.getLogin())
      )
    );
  }

  listForUser() {
    return CoreService.snapshotChangesListToModel(
      this.db.list('zakaz', ref =>
        ref.orderByChild('user').equalTo(this.authService.getLogin())
      )
    );
  }

  detailZakaz(id: string): ObservableFirebaseDetail {
    return CoreService.snapshotChangesObjectToModel(
      this.db.object(`zakaz/${id}`)
    );
  }

  detailZakazTovars(id: string): ObservableFirebaseList {
    return CoreService.snapshotChangesListToModel(
      this.db.list(`zakaztovar/${id}`)
    );
  }

  delete(id: string) {
    this.coreService.presentAlertInButton('Удалить заказ?', 'Предупреждение').then(() => {
      this.db.object(`zakaz/${id}`).remove();
    });
  }
}
